<template>
    <div id="features">
      <div class="container-fluid">
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col cols="3">
                <b-form-input
                  type="text"
                  :placeholder="$t('full-name')"
                  v-model="filterData['full_name']"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="3">
                <b-form-input
                  type="text"
                  :placeholder="$t('client-phone')"
                  v-model="filterData['phone']"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="3">
                <b-form-input
                  type="text"
                  :placeholder="$t('reservation-number')"
                  v-model="filterData['reservation_no']"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
                </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:cell(icon)="data">
                    <i :class="data.item.icon"></i>
              </template>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('dashboard') }}</h4>
            </template>
            <template v-slot:headerAction>
            </template>
            <template v-slot:body>
              <b-table responsive  :items="hotelReservations.data" :fields="fields" class="mb-0 table-borderless">
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="hotelReservations.total"
                  :per-page="hotelReservations.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'Hotel Reservation',
  data () {
    return {
      filterData: {
        page: 1,
        full_name: '',
        reservation_no: '',
        phone: '',
        id: this.$route.params.id,
        kr_name: ''
      },
      payload: {
        kr_name: '',
        feature_id: ''
      },
      showFeatures: {},
      icons: [
        {
          icon: 'fas fa-utensils',
          title: 'Food'
        },
        {
          icon: 'fas fa-glass-martini-alt',
          title: 'Bar'
        },
        {
          icon: 'fas fa-dumbbell',
          title: 'Gym'
        },
        {
          icon: 'fas fa-swimmer',
          title: 'Pool'
        },
        {
          icon: 'fas fa-hot-tub',
          title: 'Sauna'
        },
        {
          icon: 'fas fa-concierge-bell',
          title: 'Room Service'
        },
        {
          icon: 'fas fa-gamepad',
          title: 'Game Room'
        },
        {
          icon: 'fas fa-parking',
          title: 'Parking'
        }
      ]
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('full-name'),
          key: 'full_name'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('phone'),
          key: 'phone'
        },
        {
          label: this.$t('reservation-number'),
          key: 'reservation_no'
        },
        {
          label: this.$t('checkin'),
          key: 'checkin'
        },
        {
          label: this.$t('checkout'),
          key: 'checkout'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getHotelReservationsById(this.filterData, this.$route.params.id)
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getHotelReservationsById(this.filterData, this.$route.params.id)
      },
      deep: true
    }
  },
  mounted () {
    this.getHotelReservationsById(this.filterData)
  }
}
</script>
